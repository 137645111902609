//Load bootstraps default varibales
@import '~bootstrap/scss/bootstrap.scss';

//Overrides below
.hcs-content {
  padding-left: 18rem;
  padding-top: 1rem;
}

.hcs-anonymous {
  margin-top: 1rem;

  .hcs-branding-logo {
    height: 50px !important;
  }
}

.navbar-hcs, .navbar-light {
  background-color: rgba(0, 82, 111, 1);

  .text-uppercase {
    letter-spacing: 0.1em;
  }
  
  .navbar-brand {
    color: $white;
    margin-left: auto;
    margin-right: auto;

    .hcs-branding-logo {
      height: 50px !important;
      display:inline;
    }
  }

  .navbar-nav {
    .nav-item {
      color: $white;

      &.disabled {
        color: $navbar-light-disabled-color;
      }

      .show > .nav-item,
      .active > .nav-item,
      .nav-item.show,
      .nav-item.active {
        color: $white;
      }
    }

    .nav-item > .nav-link:focus, 
    .nav-item > .nav-link:hover {
      background-color: rgba($white, 0.50);
      color: $white;
    }

    .nav-link,
    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active{
      color: $white;
    }
    
    .nav-item > .nav-link.active {
      background-color: rgba($gray-800, 0.50);
      color: $white;
    }
    .nav-link.active:focus, 
    .nav-link.active:hover {
      background-color: unset;
      background-color: rgba($white, 0.50);
      color: $white;
    }
  }

  .navbar-toggler {
    color: $white;
    border-color: $white;
  }

  .navbar-toggler-icon {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$white}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"));
  }

  .navbar-text {
    color: $white;
    a {
      color: $white;

      :focus, :hover {
        color: $gray-500;
      }
    }
  }
}

.required:after {
  content: " *";
  color: $red;
}

.hcs-select_custom {
  margin-bottom:.5rem;
  margin-right:1rem;
  position:relative;
  display:inline-block;
  vertical-align:bottom;
  line-height:1;
  font-size:.875rem;

  .hcs-select_custom__label {
    margin-bottom:0;
    display:block;
    font-weight:400;
    font-family:Barlow,sans-serif;
    cursor:pointer;

    span {
      display:block
    }
  }

  .hcs-select_custom__input {
    margin-top:.5rem;
    padding-left:.5rem;
    padding-right:.5rem;
    --bg-opacity:1;
    background-color:#fff;
    background-color:rgba(255,255,255,var(--bg-opacity));
    border-radius:.5rem;
    transition-property:all;
    transition-duration:.3s;
    transition-timing-function:cubic-bezier(.4,0,.2,1);
    border:1px solid #cbd5e0;
    min-height:2rem;
  }

  .hcs-select_custom__input:focus {
    outline:0;
    border-color:#00a9e7;
  }

  .hcs-select_custom__input:disabled {
    cursor:not-allowed;    
    color:#4a5568;
    background-color:#cbd5e0;
    opacity: 1;
  }

  select.hcs-select_custom__input:invalid  {
    color: #a0aec0;
  }
  select.hcs-select_custom__input option {
    color: $black;
  }
}

.hcs-label-text__error-msg {
  color: #c9634d;
  font-size: 0.75rem;
  padding-left: 0.25rem;
  display: block;
  position: relative;
  top: 0.25rem;
}

.input-validation-error {
  border-color: #CC2600 !important;
  background-color: #FFE2DC !important;
}

$alert-bg-scale-hover: -95% !default;
$alert-border-scale-hover: -85% !default;
$alert-color-scale-hover: 0% !default;

.hcs-file-drag-drop {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30rem;
  max-width: 50rem;
  height: 10rem;
  border-width: 0.15rem;
  border-radius: 1rem;
  background-color: shift-color($secondary, $alert-bg-scale-hover);
  border-color: shift-color($secondary, $alert-border-scale-hover);
  color: shift-color($secondary, $alert-color-scale-hover);
  border-style: dashed;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.hcs-file-drag-drop-drop-active {  
  background-color: shift-color($secondary, $alert-bg-scale);
  border-color: shift-color($secondary, $alert-border-scale);
  color: shift-color($secondary, $alert-color-scale);
}

.hcs-file-drag-drop-errors {
  width:100%;
  max-width: 50rem;
  min-width: 30rem;
}

.hcs-logo-preview {
  width:auto;
  max-width: 50rem;
  max-height: 10rem;
  min-width: 25rem;
  min-height: 5rem;

  .hcs-logo-preview-item {
    width: auto;
    max-width: 50rem;
    max-height: 10rem;
    min-width: 25rem;
    min-height: 5rem;
    border-width: 0.10rem;
    border-color: $gray-400;
    border-style: dashed;
  }

  .hcs-logo-message {
    width:100%;
    max-width: 50rem;
    min-width: 30rem;
  }
}